// assets
import {
    IconDashboard,
    IconReport,
    IconFileInvoice,
    IconUsers,
    IconAdjustmentsHorizontal,
    IconMessages,
    IconPhoneCall,
    IconMessage,
    IconCalendarEvent,
    IconSquareRoundedCheck,
    IconSchool,
} from "@tabler/icons";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import IconUsersGroup from 'assets/images/icons/users-group.svg';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconAdjustmentsHorizontal,
    IconMessages,
    IconPhoneCall,
    IconMessage,
    IconCalendarEvent,
    IconReport,
    IconFileInvoice,
    WidgetsOutlinedIcon,
    LocalPoliceOutlinedIcon,
    IconSquareRoundedCheck,
    IconUsersGroup,
    IconSchool,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: "dashboard",
    title: "",
    type: "group",
    children: [
        {
            id: "default",
            title: "Dashboard",
            type: "item",
            url: "/dashboard/overview",
            icon: icons.IconDashboard,
            breadcrumbs: false,
        },
        {
            id: "ambassadors",
            title: "Ambassadors",
            type: "item",
            url: "/dashboard/ambassadors",
            icon: icons.IconUsers,
            breadcrumbs: false,
        },
        {
            id: "prospects",
            title: "Prospects",
            type: "item",
            url: "/dashboard/prospects",
            icon: icons.IconUsersGroup,
            breadcrumbs: false,
        },
        // TODO bring back when we have chats
        // {
        //   id: "widgets",
        //   title: "Widgets & Integrations",
        //   type: "item",
        //   url: "/dashboard/widgets-and-integrations",
        //   icon: icons.WidgetsOutlinedIcon,
        //   breadcrumbs: false,
        // },
        {
            id: "moderation",
            title: "Resources / Training",
            type: "item",
            url: "/dashboard/moderation",
            icon: icons.LocalPoliceOutlinedIcon,
            breadcrumbs: false,
        },
        {
            id: "chats",
            title: "Threads",
            type: "item",
            url: "/dashboard/conversations",
            icon: icons.IconMessage,
            breadcrumbs: false,
        },
        // {
        //   id: "conversations",
        //   title: "Calls",
        //   type: "item",
        //   url: "/dashboard/conversations",
        //   icon: icons.IconPhoneCall,
        //   breadcrumbs: false,
        // },
        {
            id: "shifts",
            title: "Shifts",
            type: "item",
            url: "/dashboard/shifts",
            icon: icons.IconCalendarEvent,
            breadcrumbs: false,
        },
        {
            id: "tasks",
            title: "Tasks",
            type: "item",
            url: "/dashboard/tasks",
            icon: icons.IconSquareRoundedCheck,
            breadcrumbs: false,
        },
        // {
        //     id: "school",
        //     title: "School Preferences",
        //     type: "item",
        //     url: "/dashboard/school",
        //     icon: icons.IconSchool,
        //     breadcrumbs: false,
        // },
        {
            id: "settings",
            title: "Admin Settings",
            type: "item",
            url: "/dashboard/settings",
            icon: icons.IconAdjustmentsHorizontal,
            breadcrumbs: false,
        },
        // {
        //   id: "invoices",
        //   title: "Invoices",
        //   type: "item",
        //   url: "/dashboard/invoices",
        //   icon: icons.IconFileInvoice,
        //   breadcrumbs: false,
        // },
    ],
};

export default dashboard;
