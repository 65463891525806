import PropTypes from "prop-types";

import { logEvent } from "firebase/analytics";
import { analytics as firebaseInstance } from "utils/firebase";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Drawer,
  useMediaQuery,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";
import { useSelector } from "react-redux";

// project imports
import MenuList from "./MenuList";
import LogoSection from "../LogoSection";
import MenuCard from "./MenuCard";
import { drawerWidth } from "store/constant";

import { IconMenu2 } from "@tabler/icons";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";

// ==============================|| LEFT SIDEBAR DRAWER ||============================== //

const LeftSideBar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const account = useSelector((state) => state.account);
  const { shiftPostings: allChampions } = useSelector((state) => state.shifts);
  const invitedChampion = allChampions.length > 0 || true; // TODO remove (disable funneling screen)
  const { userID, championID } = account;
  const isChampion = Boolean(championID);

  console.log("SPOOKS ACCOUNT:", account);

  if (isChampion || !invitedChampion) return null;

  const drawer = (
    <>
      <Box
        sx={{ display: "flex", p: 2, pl: 0 /*{ xs: "block" , md: 'none' }*/ }}
      >
        <Box sx={{ display: "flex", mx: "auto", mt: 2, ml: 2.5 }}>
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{
            display: "none",
            borderRadius: "12px",
            overflow: "hidden",
            mt: 1,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.primary.dark,
              color: theme.palette.secondary.light,
              "&:hover": {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
              },
            }}
            onClick={drawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            paddingRight: "16px",
            position: "relative",
          }}
        >
          <MenuList />
          <a
            target="_blank"
            href="https://tally.so/r/w4rAy5"
          >
            <Card
              sx={{
                p: "20px",
                background: "transparent",
                color: theme.palette.grey[200],
                position: "absolute",
                bottom: "50px",
                cursor: "pointer",
                ":hover": {
                  background: theme.palette.primary.main,
                },
                width: "85%",
              }}
              onClick={() => {
                logEvent(firebaseInstance, "submit_feedback", {
                  origin: "client_dashboard",
                  isChampion,
                  userID,
                });
              }}
            >
              {" "}
              <FeedbackOutlinedIcon sx={{ mr: "15px", mb: "-7px" }} />
              Submit Feedback
            </Card>
          </a>
          <MenuCard />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
          <MenuCard />
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.secondary.dark, // green sidebar
            color: theme.palette.text.primary,
            borderRight: "none",
            // [theme.breakpoints.up('md')]: {
            //     top: '88px'
            // }
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

LeftSideBar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default LeftSideBar;
