import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
    AppBar,
    Box,
    CssBaseline,
    Toolbar,
    useMediaQuery,
} from "@mui/material";

import * as Sentry from "@sentry/react";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import LeftSideBar from "./LeftSideBar";
import PostShiftDrawer from "./PostShiftDrawer";
import PostEventDrawer from "./PostEventDrawer";
// import ViewShiftDrawer from "./ViewShiftDrawer";
import ViewTimesheetDrawer from "./ViewTimesheetDrawer";
import Customization from "../Customization";
import navigation from "menu-items";
import { drawerWidth } from "store/constant";

import { useDispatch, useSelector } from "react-redux";
import {
    ACCOUNT_UPDATE,
    SET_LEFT_MENU,
    SET_POST_SHIFT_DRAWER,
    SET_POST_EVENT_DRAWER,
    SET_VIEW_SHIFT_DRAWER,
    SET_VIEW_EVENT_DRAWER,
    SET_VIEW_TIMESHEET_DRAWER,
    fetchNotifications,
    fetchPastEvents,
    MENU_OPEN,
    PROSPECTS_ADD_OR_UPDATE,
    PROSPECTS_DELETE,
    THREADS_ADD_OR_UPDATE,
    THREADS_DELETE,
    NOTIFICATIONS_ADD_OR_UPDATE,
    fetchResources,
    fetchGroups,
} from "store/actions";
import { fetchShifts, fetchEvents } from "../../store/actions";

import dashboard from "menu-items/dashboard";

// assets
import { IconChevronRight } from "@tabler/icons-react";
import axios from "axios";
import ViewEventDrawer from "./ViewEventDrawer";
import { requestFlags } from "store/requests";
import { auth, db } from "utils/firebase";
import { signInWithCustomToken } from "firebase/auth";
import {
    getFirestore,
    collection,
    query,
    where,
    onSnapshot,
    doc,
    getDoc,
    Unsubscribe,
    updateDoc,
    Timestamp,
} from "firebase/firestore";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up("md")]: {
                marginLeft: -(drawerWidth - 20),
                width: `calc(100% - ${drawerWidth}px)`,
            },
            [theme.breakpoints.down("md")]: {
                marginLeft: "20px",
                width: `calc(100% - ${drawerWidth}px)`,
                padding: "16px",
            },
            [theme.breakpoints.down("sm")]: {
                marginLeft: "10px",
                width: `calc(100% - ${drawerWidth}px)`,
                padding: "16px",
                marginRight: "10px",
            },
        }),
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down("md")]: {
                marginLeft: "20px",
            },
            [theme.breakpoints.down("sm")]: {
                marginLeft: "10px",
            },
        }),
    })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const location = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    // Handle left drawer
    const leftDrawerOpened = useSelector(
        (state) => state.customization.leftOpened
    );
    const postShiftDrawerOpened = useSelector(
        (state) => state.customization.postShiftOpened
    );

    const postEventDrawerOpened = useSelector(
        (state) => state.customization.postEventOpened
    );

    const postShiftID = useSelector((state) => state.customization.postShiftID);
    const postEventID = useSelector((state) => state.customization.postEventID);
    const recurringSeries = useSelector((state) => state.customization.recurringSeries);
    const postEventTime = useSelector((state) => state.customization.postEventTime);
    const postEventEnd = useSelector((state) => state.customization.postEventEnd);
    const viewShiftID = useSelector((state) => state.customization.viewShiftID);
    const viewEventID = useSelector((state) => state.customization.viewEventID);
    const viewTimesheetShiftID = useSelector(
        (state) => state.customization.viewTimesheetShiftID
    );

    const fetchPage = useSelector((state) => state.events.fetchPage);
    const firstEventsFetchMade = useSelector((state) => state.events.firstEventsFetchMade);
    const fetchPageLimit = useSelector((state) => state.events.fetchPageLimit);
    const doneFetching = useSelector((state) => state.events.doneFetching);

    const notificationsFetchPage = useSelector((state) => state.notifications.fetchPage);
    const notificationsFetchPageLimit = useSelector((state) => state.notifications.fetchPageLimit);

    const account = useSelector((state) => state.account);
    const { userID, companyID, accessToken, companySlug, email } = account;

    const unsubscribeProspectsRef = useRef(null);
    const unsubscribeProspectsSchoolRef = useRef(null);
    const unsubscribeChatRef = useRef(null);
    const unsubscribeNotificationsRef = useRef(null);

    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_LEFT_MENU, leftOpened: !leftDrawerOpened });
    };
    const handlePostShiftDrawerToggle = () => {
        dispatch({
            type: SET_POST_SHIFT_DRAWER,
            postShiftOpened: !postShiftDrawerOpened,
            postShiftID: -1,
        });
    };

    const handlePostEventDrawerToggle = () => {
        dispatch({
            type: SET_POST_EVENT_DRAWER,
            postEventOpened: !postEventDrawerOpened,
            postEventID: -1,
            recurringSeries: null,
            postEventTime: null,
            postEventEnd: null,
        });
    };

    const handleViewEventDrawerClose = () => {
        dispatch({
            type: SET_VIEW_EVENT_DRAWER,
            viewEventID: -1,
        });
    };

    const handleViewShiftDrawerClose = () => {
        dispatch({
            type: SET_VIEW_SHIFT_DRAWER,
            viewShiftID: -1,
        });
    };

    const handleViewTimesheetDrawerClose = () => {
        dispatch({
            type: SET_VIEW_TIMESHEET_DRAWER,
            viewTimesheetShiftID: -1,
        });
    };

    const fetchFirebase = async () => {
        if (companyID && accessToken && companySlug && userID && email) {
            axios
                .post(
                    `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/users/create`,
                    {
                        id: userID,
                        email,
                        type: "employer",
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(async (firebaseResp) => {
                    // Now authenticate with Firebase
                    try {
                        const firebaseToken = firebaseResp.data.token;
                        console.log("Firebase token:", firebaseToken);
                        const userCredential = await signInWithCustomToken(
                            auth,
                            firebaseToken
                        );

                        if (
                            "admin_" + userCredential.user?.uid ===
                            firebaseResp.data.uid
                        ) {
                            console.log("Successfully signed in with custom token!");
                        }

                        dispatch({
                            type: ACCOUNT_UPDATE,
                            payload: {
                                firebaseEmployerID: firebaseResp.data.uid,
                            },
                        });

                        // Set up snapshot listener for prospects of this school
                        const prospectQuery = query(
                            collection(db, "users"),
                            where("type", "==", "prospect"),
                            where("schools", "array-contains", companySlug)
                        );
                        if (unsubscribeProspectsRef.current) {
                            unsubscribeProspectsRef.current();
                        }

                        unsubscribeProspectsRef.current = onSnapshot(prospectQuery, (snapshot) => {
                            snapshot.docChanges().forEach((change) => {
                                if (change.type === "removed") {
                                    dispatch({
                                        type: PROSPECTS_DELETE,
                                        deletedProspectID: change.doc.id,
                                    });
                                } else {
                                    const prospectData = change.doc.data();
                                    if (prospectData.agreedToMarketing?.length > 0 && prospectData.agreedToMarketing?.includes(companySlug)) {
                                        prospectData.agreedToMarketing = true;
                                    } else {
                                        prospectData.agreedToMarketing = false;
                                    }
                                    dispatch({
                                        type: PROSPECTS_ADD_OR_UPDATE,
                                        updatedProspect: {
                                            id: change.doc.id,
                                            ...prospectData,
                                        }
                                    });
                                }
                            });
                        });

                        // Set up snapshot listener for prospect-specific info of this school
                        const prospectSchoolQuery = collection(db, "schools", companySlug, "prospects");
                        if (unsubscribeProspectsSchoolRef.current) {
                            unsubscribeProspectsSchoolRef.current();
                        }

                        unsubscribeProspectsSchoolRef.current = onSnapshot(prospectSchoolQuery, (snapshot) => {
                            snapshot.docChanges().forEach((change) => {
                                if (change.type === "removed") {
                                    dispatch({
                                        type: PROSPECTS_DELETE,
                                        deletedProspectID: change.doc.id,
                                    });
                                } else {
                                    const prospectData = change.doc.data();
                                    dispatch({
                                        type: PROSPECTS_ADD_OR_UPDATE,
                                        updatedProspect: {
                                            id: change.doc.id,
                                            ...prospectData,
                                        }
                                    });
                                }
                            });
                        });

                        // Set up the snapshot listener for threads (TODO move this so it only happens when the user is on the threads page)
                        const schoolRef = doc(db, "schools", companySlug);
                        const oneYearAgo = new Date();
                        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                        const chatQuery = query(
                            collection(db, "threads"),
                            where("school", "==", schoolRef),
                            where("lastMessageTime", ">=", Timestamp.fromDate(oneYearAgo))
                        );

                        if (unsubscribeChatRef.current) {
                            unsubscribeChatRef.current();
                        }

                        unsubscribeChatRef.current = onSnapshot(chatQuery, (snapshot) => {
                            snapshot.docChanges().forEach((change) => {
                                if (change.type === "removed") {
                                    dispatch({
                                        type: THREADS_DELETE,
                                        deletedThreadID: change.doc.id,
                                    });
                                } else {
                                    dispatch({
                                        type: THREADS_ADD_OR_UPDATE,
                                        updatedThread: {
                                            id: change.doc.id,
                                            ...change.doc.data(),
                                        },
                                    });
                                }
                            });
                        });

                        // Set up the snapshot listener for notifications (sub-collection of current user document)
                        const oneWeekAgo = new Date();
                        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
                        const userDocRef = doc(db, "users", firebaseResp.data.uid);
                        const notificationsQuery = query(
                            collection(userDocRef, "notifications"),
                            where("createdAt", ">=", Timestamp.fromDate(oneWeekAgo))
                        );
                        if (unsubscribeNotificationsRef.current) {
                            unsubscribeNotificationsRef.current();
                        }

                        unsubscribeNotificationsRef.current = onSnapshot(notificationsQuery, (snapshot) => {
                            snapshot.docChanges().forEach((change) => {
                                if (change.type === "removed") {
                                } else {
                                    console.log("Adding or updating notification", change.doc.data());
                                    dispatch({
                                        type: NOTIFICATIONS_ADD_OR_UPDATE,
                                        updatedNotification: {
                                            id: change.doc.id,
                                            attributes: {
                                                ...change.doc.data(),
                                                createdAt: change.doc.data().createdAt?.toDate().toISOString(),
                                            }
                                        },
                                    });
                                }
                            });
                        });

                        // Set up the snapshot listener for ambassadors
                        // const ambassadorQuery = query(
                        //     collection(db, "users"),
                        //     where("type", "==", "worker"),
                        //     where("company", "==", companySlug)
                        // );
                        // const unsubscribeAmbassadors = onSnapshot(ambassadorQuery, (snapshot) => {
                        //     snapshot.docChanges().forEach((change) => {
                        //         if (change.type === "removed") {
                        //             dispatch({
                        //                 type: PROSPECTS_DELETE,
                        //                 deletedProspectID: change.doc.id,
                        //             });
                        //         } else {
                        //             const prospectData = change.doc.data();
                        //             dispatch({
                        //                 type: PROSPECTS_ADD_OR_UPDATE,
                        //                 updatedProspect: {
                        //                     id: change.doc.id,
                        //                     ...prospectData,
                        //                 }
                        //             });
                        //         }
                        //     });
                        // });

                    } catch (error) {
                        console.error("Error signing in with custom Firebase token:", error);
                        Sentry.captureException(error);
                        return;
                    }
                })
                .catch((error) => {
                    console.error(
                        "An error occurred trying to create a Firebase user:",
                        error
                    );
                    Sentry.captureException(error);
                });
        }
    };

    useEffect(() => {
        console.log("Main Layout B");
        if (companyID && accessToken && userID) {
            console.log("Dispatching fetchShifts action using ", accessToken);
            dispatch(
                fetchShifts(
                    `filters[company][id][$eq]=${companyID}&filters[type][$ne]=prospect&populate=*&pagination[limit]=-1`,
                    accessToken,
                )
            );

            // This retrieves all scheduled and live events
            const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
            const yesterdayTimeUTCString = yesterday.toISOString().slice(0, 19);
            dispatch(
                fetchEvents(
                    `filters[company]=${companyID}&filters[status][$ne]=draft&filters[status][$ne]=cancelled&filters[publishedAt][$null]=false&filters[$and][1][time][$gte]=${yesterdayTimeUTCString}&populate[shifts][populate][0]=user&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups&sort[0]=time%3Aasc&pagination[pageSize]=${fetchPageLimit}&pagination[page]=${fetchPage}`,
                    accessToken
                )
            );

            const currentTime = new Date();
            const currentTimeUTCString = currentTime.toISOString().slice(0, 19);
            dispatch(
                fetchPastEvents(
                    `filters[company]=${companyID}&filters[status][$ne]=draft&filters[status][$ne]=cancelled&filters[$and][1][time][$lte]=${currentTimeUTCString}&populate[shifts][populate][0]&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups&sort[0]=time%3Adesc&pagination[limit]=-1`,
                    accessToken
                )
            );

            axios
                .get(
                    `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/companies/${companyID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    const { data } = response;
                    console.log("COMPANY RESP", response);
                    if (!data || !data.data || !data.data.attributes) {
                        console.log("Unable to parse business response from server");
                        return;
                    }
                    dispatch({
                        type: ACCOUNT_UPDATE,
                        payload: {
                            companyName: data.data.attributes.name,
                            companyWebsite: data.data.attributes.websiteUrl,
                            paymentsEnabled: data.data.attributes.paymentsEnabled,
                            autoClocking: data.data.attributes.autoClocking,
                        },
                    });
                })
                .catch((err) => {
                    console.error(err);
                    Sentry.captureException(err);
                });

            // Get last fortnite's time
            const lastFortnite = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
            const lastFortniteTimeUTCString = lastFortnite.toISOString().slice(0, 19);
            dispatch(
                fetchNotifications(
                    `filters[$or][0][company]=${companyID}&filters[$or][1][recipientID]=${userID}&filters[$and][1][createdAt][$gte]=${lastFortniteTimeUTCString}&sort[0]=createdAt%3Adesc&pagination[limit]=50`,
                    accessToken));

            console.log("Fetching resources");
            dispatch(fetchResources(
                `filters[company][id][$eq]=${companyID}&populate=*&pagination[limit]=-1`,
                accessToken
            ))
            console.log("Fetching groups");
            dispatch(fetchGroups(
                `filters[company][id][$eq]=${companyID}&populate=*&pagination[limit]=-1`,
                accessToken
            ))
        }
    }, [dispatch, companyID, accessToken, userID]);

    useEffect(() => {
        fetchFirebase();
    }, [dispatch, companyID, accessToken, userID, companySlug, email]);

    useEffect(() => {
        if (companyID && accessToken && firstEventsFetchMade && !doneFetching) {
            // This retrieves all scheduled and live events
            const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
            const yesterdayTimeUTCString = yesterday.toISOString().slice(0, 19);
            dispatch(
                fetchEvents(
                    `filters[company]=${companyID}&filters[status][$ne]=draft&filters[status][$ne]=cancelled&filters[publishedAt][$null]=false&filters[$and][1][time][$gte]=${yesterdayTimeUTCString}&populate[shifts][populate][0]=user&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups&sort[0]=time%3Aasc&pagination[pageSize]=${fetchPageLimit}&pagination[page]=${fetchPage}`,
                    accessToken
                )
            );
        }
    }, [dispatch, fetchPage, firstEventsFetchMade]);

    useEffect(() => {
        const curLocation = location.pathname;
        dashboard.children.some((item) => {
            if (curLocation.includes(item.url)) {
                dispatch({ type: MENU_OPEN, id: item.id });
                return true;
            }
            return false;
        });
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                // Re-issue Firebase auth token (expires after 1h) every time the tab is re-focused
                // TODO this is a bit aggressive - we should only do this if the token is about to expire
                fetchFirebase();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return !!userID && !!accessToken && !!companyID ? (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened
                        ? theme.transitions.create("width")
                        : "none",
                }}
            >
                <Toolbar>
                    <Header
                        handleLeftDrawerToggle={handleLeftDrawerToggle}
                        handlePostShiftDrawerOpen={handlePostShiftDrawerToggle}
                    />
                </Toolbar>
            </AppBar>

            {/* left drawer */}
            <LeftSideBar
                drawerOpen={leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
            />

            {/* right drawer */}
            <PostShiftDrawer
                drawerOpen={postShiftDrawerOpened}
                onDrawerClose={handlePostShiftDrawerToggle}
                shiftID={postShiftID}
            />

            <PostEventDrawer
                drawerOpen={postEventDrawerOpened}
                onDrawerClose={handlePostEventDrawerToggle}
                eventID={postEventID}
                recurringSeries={recurringSeries}
                eventTime={postEventTime}
                eventEnd={postEventEnd}
            />

            <ViewEventDrawer
                drawerOpen={viewEventID > 0}
                drawerClose={handleViewEventDrawerClose}
                eventID={viewEventID}
            />

            {/* right drawer */}
            {/* <ViewShiftDrawer
        drawerOpen={viewShiftID > 0}
        drawerClose={handleViewShiftDrawerClose}
        shiftID={viewShiftID}
      /> */}

            {/* right drawer */}
            <ViewTimesheetDrawer
                drawerOpen={viewTimesheetShiftID > 0}
                drawerClose={handleViewTimesheetDrawerClose}
                shiftID={viewTimesheetShiftID}
            />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs
                    separator={IconChevronRight}
                    navigation={navigation}
                    icon
                    title
                    rightAlign
                />
                <Outlet />
            </Main>
            <Customization />
        </Box>
    ) : null;
};

export default MainLayout;
