import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { IconBell } from '@tabler/icons';
import { Badge, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { usePopover } from '../../../../hooks/usePopover';
import { NotificationsPopover } from './notifications-popover';
import { NOTIFICATIONS_HIDE_ONE, NOTIFICATIONS_MARK_ALL_AS_READ, SET_POST_EVENT_DRAWER, SET_VIEW_EVENT_DRAWER } from 'store/actions';

export const NotificationsButton = () => {
    const popover = usePopover();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    // const { handleHideOne, handleMarkAllAsRead, notifications, unread } = useNotifications();
    const { notifications, lastAllReadTime, notificationsHidden } = useSelector((state) => state.notifications);
    const { shiftPostings: allAmbassadors, adminUsers, firstFetchMade } = useSelector((state) => state.shifts);

    const nonHiddenNotifications = notifications.filter((notification) => !notificationsHidden.includes(notification.id) && !notification.attributes.type.includes('ForAmbassador'));

    const unread = useMemo(() => {
        if (!lastAllReadTime) return nonHiddenNotifications.length;
        const lastReadDate = new Date(lastAllReadTime);
        for (let i = 0; i < nonHiddenNotifications.length; i++) {
            const notificationDate = new Date(nonHiddenNotifications[i].attributes.createdAt);
            if (notificationDate <= lastReadDate) {
                return i;
            }
        }
        return nonHiddenNotifications.length;
    }, [nonHiddenNotifications, lastAllReadTime]);

    const handleHideOne = useCallback((notificationId) => {
        // TODO save to localstorage

        dispatch({
            type: NOTIFICATIONS_HIDE_ONE,
            payload: notificationId
        });
    }, []);

    const handleMarkAllAsRead = useCallback(() => {
        dispatch({
            type: NOTIFICATIONS_MARK_ALL_AS_READ,
        });
    }, []);

    const handleNotificationClick = useCallback((notification) => {
        popover.handleClose();

        if (!notification || !notification.attributes) return;

        if (notification.attributes.type === 'shiftClaimedForBoth' || notification.attributes.type === 'shiftCancelledForBoth') {
            dispatch({
                type: SET_VIEW_EVENT_DRAWER,
                viewEventID: notification.attributes.associatedPostingID,
            });
            return;
        }

        if (notification.attributes.type === 'changedAvailabilityForBoth' || notification.attributes.type === 'changedChatsAvailabilityForBoth') {
            const ambassadorID = notification.attributes.associatedUserID;

            // Navigate to the ambassador's profile
            navigate(`/dashboard/ambassadors/${ambassadorID}`);
            return;
        }

        if (notification.attributes.type === 'recurringAssignedForBoth' || notification.attributes.type === 'recurringCancelledForBoth') {
            dispatch({
                type: SET_POST_EVENT_DRAWER,
                postEventOpened: true,
                postEventID: notification.attributes.associatedPostingID,
                recurringSeries: true,
            });
            return;
        }

        if (notification.attributes.type === 'taskCommentAdded' || notification.attributes.type === 'taskMarkedDone' || notification.attributes.type === 'taskCollaboratorAdded' || notification.attributes.type === 'taskDetailsModified') {
            navigate(`/dashboard/tasks?view=${notification.attributes.associatedTaskID}&boards=${notification.attributes.associatedBoards}`)
            return;
        }

        // TODO open recurring shift drawer if recurring notification

        if (notification.attributes.type === 'newSuggestionRequest') {
            navigate(`/dashboard/conversations?view=${notification.attributes.threadId}`)
            return;
        }

        if (notification.attributes.type === 'moduleCompletedForBoth') {
            navigate(`/dashboard/moderation?view=${notification.attributes.associatedResourceID}`)
            return;
        }

    }, []);

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton
                    ref={popover.anchorRef}
                    onClick={() => {
                        handleMarkAllAsRead();
                        popover.handleOpen();
                    }}
                    sx={{ mr: 1 }}
                >
                    <Badge
                        color="success"
                        badgeContent={unread}
                    >
                        <IconBell strokeOpacity={1} strokeWidth="2px" width="30px" height="30px" />
                    </Badge>
                </IconButton>
            </Tooltip>
            <NotificationsPopover
                anchorEl={popover.anchorRef.current}
                notifications={firstFetchMade ? nonHiddenNotifications : []}
                ambassadors={allAmbassadors}
                admins={adminUsers}
                notificationsHidden={notificationsHidden}
                onNotificationClick={handleNotificationClick}
                onClose={popover.handleClose}
                // onMarkAllAsRead={handleMarkAllAsRead}
                onHideOne={handleHideOne}
                open={popover.open}
            />
        </>
    );
};
