import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "config";
import Logo from "ui-component/Logo";
import { MENU_OPEN } from "store/actions";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ hide = false, dark = false, width = null, publicMode = false }) => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { championID } = account;
  const isChampion = Boolean(championID);

  return publicMode ? (
    <a href="https://www.mychampions.io">
      <Logo width={width} dark={dark} />
    </a>
  ) : (
    <ButtonBase
      sx={ hide ? { display: "none" } : null }
      disableRipple
    //   onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
      component={Link}
      to={
        isChampion ? `/dashboard/ambassadors/${championID}` : config.defaultPath
      }
    >
      <Logo width={width} dark={dark} />
    </ButtonBase>
  );
};

export default LogoSection;
