import { call, put, takeEvery } from "redux-saga/effects";
import { requestShifts, requestConvos, requestEvents, requestSuggestions, requestRules, requestNotifications, requestFlags, requestResources, requestGroups } from "./requests";
import {
    SHIFTS_FETCH_REQUESTED,
    SHIFTS_FETCH_SUCCEEDED,
    SHIFTS_FETCH_FAILED,
    CONVOS_FETCH_REQUESTED,
    CONVOS_FETCH_SUCCEEDED,
    CONVOS_FETCH_FAILED,
    EVENTS_FETCH_REQUESTED,
    EVENTS_FETCH_PAST_REQUESTED,
    EVENTS_FETCH_PAST_SUCCEEDED,
    EVENTS_FETCH_SUCCEEDED,
    EVENTS_FETCH_FAILED,
    RULES_FETCH_REQUESTED,
    RULES_FETCH_SUCCEEDED,
    RULES_FETCH_FAILED,
    NOTIFICATIONS_FETCH_REQUESTED,
    NOTIFICATIONS_FETCH_SUCCEEDED,
    NOTIFICATIONS_FETCH_FAILED,
    FLAGS_FETCH_REQUESTED,
    FLAGS_FETCH_SUCCEEDED,
    FLAGS_FETCH_FAILED,
    SUGGESTIONS_FETCH_REQUESTED,
    SUGGESTIONS_FETCH_SUCCEEDED,
    SUGGESTIONS_FETCH_FAILED,
    RESOURCES_FETCH_REQUESTED,
    RESOURCES_FETCH_SUCCEEDED,
    RESOURCES_FETCH_FAILED,
    GROUPS_FETCH_REQUESTED,
    GROUPS_FETCH_SUCCEEDED,
    GROUPS_FETCH_FAILED,
    //   ACCOUNT_BUSINESS_FETCH_REQUESTED,
    //   ACCOUNT_BUSINESS_FETCH_SUCCEEDED,
    //   ACCOUNT_BUSINESS_FETCH_FAILED,
} from "./actions";

// This saga will be fired on SHIFTS_FETCH_REQUESTED actions
function* handleFetchShifts(action) {
    try {
        const response = yield call(
            requestShifts,
            action.payload.params,
            action.payload.headers
        );
        const { data: shiftPostings } = response;
        yield put({ type: SHIFTS_FETCH_SUCCEEDED, shiftPostings });
    } catch (e) {
        yield put({ type: SHIFTS_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchConvos(action) {
    try {
        const response = yield call(
            requestConvos,
            action.payload.params,
            action.payload.headers
        );
        const { data: conversations } = response.data;
        yield put({ type: CONVOS_FETCH_SUCCEEDED, conversations });
    } catch (e) {
        yield put({ type: CONVOS_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchEvents(action) {
    try {
        const response = yield call(
            requestEvents,
            action.payload.params,
            action.payload.headers
        );
        const { data: eventPostings } = response.data;
        yield put({ type: EVENTS_FETCH_SUCCEEDED, eventPostings });
    } catch (e) {
        yield put({ type: EVENTS_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchPastEvents(action) {
    try {
        const response = yield call(
            requestEvents,
            action.payload.params,
            action.payload.headers
        );
        const { data: eventPostings } = response.data;
        yield put({ type: EVENTS_FETCH_PAST_SUCCEEDED, eventPostings });
    } catch (e) {
        yield put({ type: EVENTS_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchRules(action) {
    try {
        const response = yield call(
            requestRules,
            action.payload.params,
            action.payload.headers
        );
        const { data: rules } = response.data;
        yield put({ type: RULES_FETCH_SUCCEEDED, rules });
    } catch (e) {
        yield put({ type: RULES_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchNotifications(action) {
    try {
        const response = yield call(
            requestNotifications,
            action.payload.params,
            action.payload.headers
        );
        const { data: notifications } = response.data;
        yield put({ type: NOTIFICATIONS_FETCH_SUCCEEDED, notifications });
    } catch (e) {
        yield put({ type: NOTIFICATIONS_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchResources(action) {
    try {
        const response = yield call(
            requestResources,
            action.payload.params,
            action.payload.headers
        );
        const { data: resources } = response.data;
        yield put({ type: RESOURCES_FETCH_SUCCEEDED, resources });
    } catch (e) {
        yield put({ type: RESOURCES_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchGroups(action) {
    try {
        const response = yield call(
            requestGroups,
            action.payload.params,
            action.payload.headers
        );
        const { data: groups } = response.data;
        yield put({ type: GROUPS_FETCH_SUCCEEDED, groups });
    } catch (e) {
        yield put({ type: GROUPS_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchSuggestions(action) {
    try {
        const response = yield call(
            requestSuggestions,
            action.payload.params,
            action.payload.headers
        );
        const { data: suggestions } = response.data;
        yield put({ type: SUGGESTIONS_FETCH_SUCCEEDED, suggestions });
    } catch (e) {
        yield put({ type: SUGGESTIONS_FETCH_FAILED, message: e.message });
    }
}

function* handleFetchFlags(action) {
    try {
        const response = yield call(
            requestFlags,
            action.payload.params,
            action.payload.headers
        );
        const { data: flags } = response.data;
        yield put({ type: FLAGS_FETCH_SUCCEEDED, flags });
    } catch (e) {
        yield put({ type: FLAGS_FETCH_FAILED, message: e.message });
    }
}

// Starts handleFetchShifts on each dispatched SHIFTS_FETCH_REQUESTED action
// Allows concurrent fetches of shifts
export function* fetchShiftsSaga() {
    yield takeEvery(SHIFTS_FETCH_REQUESTED, handleFetchShifts);
}

export function* fetchConvosSaga() {
    yield takeEvery(CONVOS_FETCH_REQUESTED, handleFetchConvos);
}

export function* fetchEventsSaga() {
    yield takeEvery(EVENTS_FETCH_REQUESTED, handleFetchEvents);
}

export function* fetchPastEventsSaga() {
    yield takeEvery(EVENTS_FETCH_PAST_REQUESTED, handleFetchPastEvents);
}

export function* fetchRulesSaga() {
    yield takeEvery(RULES_FETCH_REQUESTED, handleFetchRules);
}

export function* fetchNotificationsSaga() {
    yield takeEvery(NOTIFICATIONS_FETCH_REQUESTED, handleFetchNotifications);
}

export function* fetchResourcesSaga() {
    yield takeEvery(RESOURCES_FETCH_REQUESTED, handleFetchResources);
}

export function* fetchGroupsSaga() {
    yield takeEvery(GROUPS_FETCH_REQUESTED, handleFetchGroups);
}

export function* fetchSuggestionsSaga() {
    yield takeEvery(SUGGESTIONS_FETCH_REQUESTED, handleFetchSuggestions);
}

export function* fetchFlagsSaga() {
    yield takeEvery(FLAGS_FETCH_REQUESTED, handleFetchFlags);
}
