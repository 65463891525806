import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { Navigate } from "react-router";

// dashboard routing
const DashboardDefault = Loadable(
    lazy(() => import("views/dashboard/Default"))
);

// utilities routing
const UtilsTypography = Loadable(
    lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
    lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
    lazy(() => import("views/utilities/TablerIcons"))
);

// sample page routing
const ChampionsPage = Loadable(lazy(() => import("views/champions")));
const WidgetsPage = Loadable(
    lazy(() => import("views/widgets-and-integrations"))
);
const ConversationsPage = Loadable(lazy(() => import("views/conversations")));
const ShiftsPage = Loadable(lazy(() => import('views/shifts')));
const ProspectsPage = Loadable(lazy(() => import('views/prospects')));
const ManageChampionPage = Loadable(
    lazy(() => import("views/champions/manage"))
);
const ManageProspectPage = Loadable(
    lazy(() => import("views/prospects/manage"))
);
const ViewConversationPage = Loadable(
    lazy(() => import("views/conversations/view"))
);

const ModerationPage = Loadable(lazy(() => import("views/moderation")));
const TasksPage = Loadable(lazy(() => import("views/tasks")));
const SettingsPage = Loadable(lazy(() => import("views/account-settings")));
const SchoolPreferencesPage = Loadable(lazy(() => import("views/school-preferences")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "dashboard",
            children: [
                {
                    path: "overview",
                    element: <DashboardDefault />,
                },
                {
                    path: "ambassadors",
                    element: <ChampionsPage />,
                },
                {
                    path: "ambassadors/:championId",
                    element: <ManageChampionPage />,
                },
                // {
                //   path: "champions",
                //   children: [
                //     {
                //       path: "list",
                //       element: <ChampionsPage />,
                //     },
                //     {
                //       path: ":championId",
                //       element: <ManageChampionPage />,
                //     },
                //   ],
                // },
                {
                    path: "widgets-and-integrations",
                    element: <WidgetsPage />,
                },
                {
                    path: "conversations",
                    element: <ConversationsPage />,
                },
                {
                    path: "conversations/:conversationId",
                    element: <ViewConversationPage />,
                },
                {
                    path: "shifts",
                    element: <ShiftsPage />,
                },
                {
                    path: "prospects",
                    element: <ProspectsPage />,
                },
                {
                    path: "prospects/:prospectId",
                    element: <ManageProspectPage />,
                },
                {
                    path: 'tasks',
                    element: <TasksPage />
                },
                {
                    path: 'school',
                    element: <SchoolPreferencesPage />
                },
                {
                    path: "moderation",
                    element: <ModerationPage />,
                },
                {
                    path: "settings",
                    element: <SettingsPage />,
                },
                { path: '', element: <Navigate to="overview" /> } // Redirect from /dashboard to /dashboard/overview
            ],
        },
        { path: '', element: <Navigate to="dashboard" /> } // Redirect from / to /dashboard
    ],
};

export default MainRoutes;
