import { createSlice } from '@reduxjs/toolkit';
import { objFromArray } from '../utils/objects';

const initialState = {
    isLoaded: false,
    columns: {
        byId: {
            "To Do": {
                id: "To Do",
                name: "To Do",
                taskIds: []
            },
            "In Progress": {
                id: "In Progress",
                name: "In Progress",
                taskIds: []
            },
            "Done": {
                id: "Done",
                name: "Done",
                taskIds: []
            },
        },
        allIds: [
            "To Do",
            "In Progress",
            "Done",
        ],
    },
    tasks: {
        byId: {},
        allIds: [],
    },
    toDoCount: 0,
    inProgressCount: 0,
    doneCount: 0,
    metrics: {},
    newTaskAdded: null,
};

const reducers = {
    loadingBoard(state) {
        state.isLoaded = false;
    },
    getBoard(state, action) {
        const board = action.payload;

        state.columns.byId = objFromArray(board.columns);
        state.columns.allIds = Object.keys(state.columns.byId);
        state.tasks.byId = objFromArray(board.tasks);
        state.tasks.allIds = Object.keys(state.tasks.byId);
        state.isLoaded = true;
    },
    updateColumn(state, action) {
        const column = action.payload;

        state.columns.byId[column.id] = column;
    },
    updateColumnCount(state, action) {
        const { columnId, count } = action.payload;
        console.log("updateColumnCount1", columnId, count);

        if (columnId === 'To Do') {
            state.toDoCount = count;
        } else if (columnId === 'In Progress') {
            state.inProgressCount = count;
        } else if (columnId === 'Done') {
            state.doneCount = count;
        }
    },
    createTask(state, action) {
        const task = action.payload;

        state.tasks.byId[task.id] = task;
        state.tasks.allIds.push(task.id);

        // Add the taskId reference to the column
        state.columns.byId[task.columnId].taskIds.push(task.id);
        // Increment the board metrics
        const boardsAddedTo = task.boards.split(',,'); // Should just be one board
        boardsAddedTo.forEach(boardId => {
            state.metrics[boardId] = state.metrics[boardId] ? state.metrics[boardId] + 1 : 1;
        });
        state.newTaskAdded = task.id;
    },
    resetNewTaskAdded(state) {
        state.newTaskAdded = null;
    },
    updateTask(state, action) {
        const task = action.payload;

        Object.assign(state.tasks.byId[task.id], task);
    },
    setBoardMetrics(state, action) {
        const { boardMetrics } = action.payload;

        state.metrics = boardMetrics;
    },
    incrementBoardMetrics(state, action) {
        const { boardId } = action.payload;

        state.metrics[boardId] = state.metrics[boardId] ? state.metrics[boardId] + 1 : 1;
    },
    decrementBoardMetrics(state, action) {
        const { boardId } = action.payload;

        state.metrics[boardId] = state.metrics[boardId] ? state.metrics[boardId] - 1 : 0;
    },
    moveTask(state, action) {
        const { taskId, position, columnId } = action.payload;
        const sourceColumnId = state.tasks.byId[taskId].columnId;

        // Remove task from source column
        state.columns.byId[sourceColumnId].taskIds =
            (state.columns.byId[sourceColumnId].taskIds.filter((_taskId) => _taskId !== taskId));

        // If columnId exists, it means that we have to add the task to the new column
        if (columnId) {
            // Change task's columnId reference
            state.tasks.byId[taskId].columnId = columnId;
            // Push the taskId to the specified position
            state.columns.byId[columnId].taskIds.splice(position, 0, taskId);
        } else {
            // Push the taskId to the specified position
            state.columns.byId[sourceColumnId].taskIds.splice(position, 0, taskId);
        }
    },
    deleteTask(state, action) {
        const taskId = action.payload;
        const { columnId } = state.tasks.byId[taskId];

        delete state.tasks.byId[taskId];
        state.tasks.allIds = state.tasks.allIds.filter((_taskId) => _taskId !== taskId);
        state.columns.byId[columnId].taskIds =
            (state.columns.byId[columnId].taskIds.filter((_taskId) => _taskId !== taskId));
    },
    addComment(state, action) {
        const { taskId, userID, comment } = action.payload;
        const task = state.tasks.byId[taskId];

        task.comments.push(comment);
        if (task.collaborators.indexOf(userID) === -1) {
            task.collaborators.push(userID);
        }
    },
};

export const slice = createSlice({
    name: 'kanban',
    initialState,
    reducers
});

const { reducer } = slice;
export default reducer;
